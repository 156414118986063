import React, { useContext } from "react"
import { StoreContext } from "../../context/StoreContext"

const AddToCart = React.memo(({ variantId, disabled=true }) => {
  const { addProductToCart } = useContext(StoreContext);
  return (
    <button
      className="button add-to-cart-button"
      onClick={() => addProductToCart(variantId)}
      disabled={disabled}
    >
      Add To Cart
    </button>
  )
})

export default AddToCart
